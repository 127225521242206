// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
//@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: #474545;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/** Replace the width of the container **/
.container {
    width: 1485px;
    @media screen and (max-width: 1550px) {
        width: 100%;
        padding: 0 25px;
    }
}


.masonry {
  //columns: 4;
  column-gap: 16px;
  columns: 3;
  @media (min-width: 1200px) {
  }
  @media (max-width: 992px) {
    columns: 2;
  }
   @media (max-width: 768px) {columns: 1;}

   .grid {
    display: inline-block;
    margin-bottom: 16px;
    position: relative;
    &:before {
      border-radius: 5px;
      content:'';
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background-color:rgba(0,0,0,.2);
    }
    img {
      width: 100%;
      border-radius: 5px;
    }
    &__text {
        background-color: $color-black;
        &__title {
          font-size: 28px;
          font-weight:bold;
          margin: 0px 0px 10px 0px;
        }
        &__description {
          font-size: 14px;
          font-weight:300;
        }
    }

  }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';


p {
    font-weight: 500;
    font-size: $font-size-28;
    color: #d6d6d6;
    margin: 0;
    line-height: 1.3;
    @media screen and (max-width: 1550px) {
        font-size: 1.2rem;
    }
}



.slideshow {
	width: 100%;
	overflow: hidden;
	position: relative;
	.placeholder {
		width: 100vw;
		height: auto;
		display: block;
    @media screen and (max-width: $size-slider-breakpoint){
      height: calc(100vw / 480 * 600);
    }
	}
	.slide {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
    width: 100vw;
		overflow: hidden;
    opacity: 0;
		position: absolute;
    //@include transition(right 1.5s ease-in-out);
		backface-visibility: hidden;
    transition: 1.5s;
		img {
			 width: 100vw!important;
			 height: auto;
			 display: block;
			 position: relative;
		}
		&.transiting {
			right: 0;
      left: 0;
      opacity: 1;
      //@include transition(right 1.5s ease-in-out);
      transition: 1.5s;
		}
		&.active {
			right: 0;
      left: 0;
			z-index: 10;
      opacity: 1;
      transition: 1.5s;
			//@include transition(right 1.5s ease-in-out);
		}
	}
	@media screen and (max-width: 1100px) {
	//	margin-top: 60px;
	}
}
/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text-burst {
    padding: 100px 0 50px 0;
    border-top: 25px solid #ee3124;
    h3 {
        font-weight: normal;
        font-size: $font-size-48;
        color: #d6d6d6;
    }
    .text-burst.flex {
        padding-top: 50px;
        .left-side {
            width: 60%;
            margin-right: 5%;
        }
        a.right-side {
            background: #ee3124;
            width: 27%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 55px;
            height: 330px;
            h4 {
                font-weight: normal;
                font-size: $font-size-36;
                color: $color-white;
                text-transform: uppercase;
            }
            h3 {
                font-weight: normal;
                font-size: $font-size-60;
                color: $color-white;
            }
            .icon {
                .img-responsive.hover {
                    display: none;
                }
            }

            &:hover {
                background: $color-black;
                h4, h3 {
                    color: #ee3124;
                }
                .icon {
                    .img-responsive.hover {
                        display: block;
                    }
                    .img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
}

#section-carousel-text {
    .left-side {
        width: 50%;
        margin-right: 3%;
        .main-content {
            position: relative;
            .col-xs-12.txt {
                padding-left: 0;
                img {
                    height: 400px;
                }
            }
            .owl-theme {
                .custom-nav {
                    position: absolute;
                    top: 20%;
                    left: 0;
                    right: 0;

                    .owl-prev, .owl-next {
                        position: absolute;
                        height: 100px;
                        color: inherit;
                        background: none;
                        border: none;
                        z-index: 100;

                        i {
                            font-size: 2.5rem;
                            color: #be1e2d;
                        }
                    }
                }
            }
            .arrow.prev.next-vedette {
                position: absolute;
                left: -53px;
                top: 90%;
                z-index: 1;
            }
            .arrow.next.prev-vedette {
                position: absolute;
                right: -16px;
                top: 90%;
                z-index: 1;
            }
            .arrow {
                .img-responsive.hover {
                    display: none;
                }
                &:hover {
                    .img-responsive.hover {
                        display: block;
                    }
                    .img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
    .right-side {
        width: 50%;
        padding-left: 2%;
        h3 {
            font-weight: normal;
            font-size: $font-size-48;
            padding-bottom: 20px;
            color: #d6d6d6;
        }
    }
}

#section-logo-small {
    padding: 75px 0 30px 0;
    .container.flex.items-center {
        position: relative;
        .text {
            padding-right: 25px;
            p {
                font-weight: 500;
                font-size: $font-size-28;
                color: #d6d6d6;
                margin: 0;
                line-height: 1.5;
            }
        }
        .logo-right {
            position: absolute;
            right: 6%;
            p {
                font-size: $font-size-22;
                text-align: center;
            }
        }
    }
}

#section-logo {
    padding: 10px 0;
    background: $color-black;
    .logo {
        width: 20%;
    }
}

#section-burst {
    padding: 35px 0;
    border-bottom: 25px solid #ee3124;
    a.burst {
        background: #aaa8a9;
        display: flex;
        align-items: center;
        padding: 10px 5% 0 5%;
        width: 50%;
        height: 120px;
        .text {
            width: 100%;
            h4 {
                font-weight: normal;
                font-size: $font-size-36;
                color: $color-black;
            }
            h3 {
                font-weight: normal;
                font-size: $font-size-60;
                color: $color-black;
                line-height: 1;
            }
        }
        .icon {
            .img-responsive.hover {
                display: none;
            }
        }
        &:hover {
            background: $color-black;
            .text {
                h4, h3 {
                    color: #ee3124;
                }
            }
            .icon {
                .img-responsive.hover {
                    display: block;
                }
                .img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#section-text-img {
    padding: 100px 0 50px 0;
    border-top: 25px solid #ee3124;
    .left-side {
        width: 50%;
        margin-right: 5%;
        h3 {
            font-weight: normal;
            font-size: $font-size-48;
            color: #d6d6d6;
            padding-bottom: 20px;
        }
        a.burst-red {
            background: #ee3124;
            display: flex;
            align-items: center;
            padding: 0 10%;
            width: 95%;
            height: 120px;
            margin-top: 35px;
            .text {
                width: 65%;
                h3 {
                    font-weight: normal;
                    font-size: $font-size-36;
                    color: $color-black;
                    line-height: 1;
                    padding-bottom: 0;
                    span {
                        font-size: $font-size-60;
                    }
                }
            }
            .icon {
                width: 15%;
                .img-responsive.hover {
                    display: none;
                }
            }
            &:hover {
                background: $color-black;
                .text {
                    h4, h3 {
                        color: #ee3124;
                    }
                }
                .icon {
                    .img-responsive.hover {
                        display: block;
                    }
                    .img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
}

#section-text {
    padding: 50px 0 75px 0;
    h3 {
        font-weight: normal;
        font-size: $font-size-48;
        color: #d6d6d6;
        padding-bottom: 20px;
    }
    p.red {
        font-weight: 500;
        font-size: $font-size-38;
        color: #ee3124;
        margin: 35px 0 0 0;
        line-height: 1.3;
    }
}

/*******************************************************************************
* GÉOTHERMIME
*******************************************************************************/

#section-geo-closed {
    padding-bottom: 75px;
    h3 {
        font-weight: normal;
        font-size: $font-size-48;
        color: #d6d6d6;
        padding-bottom: 20px;
    }
    .text-img {
        display: flex;
        padding-top: 35px;
        .left-side {
            width: 60%;
            margin-right: 5%;
            padding-top: 25px;
        }
        .right-side {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            .image {
                width: 50%;
                padding: 10px;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-28;
                    color: $color-white;
                    padding-top: 5px;
                }
            }
        }
    }
}

#section-geo-opened {
    background: #000;
    padding: 75px 0;
    .bloc {
        display: flex;
        .left-side {
            width: 60%;
            margin-right: 5%;
        }
        .right-side {
            width: 50%;
        }
        .right-side.flex {
            padding-top: 50px;
        }
        h3 {
            font-weight: normal;
            font-size: $font-size-48;
            color: #d6d6d6;
            padding-bottom: 20px;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-32;
            color: $color-white;
            padding-top: 5px;
        }
    }
    .bloc.pb6 {
        .left-side {
            width: 100%;
        }
    }
}

#section-img-list {
    padding: 50px 0;
    .left-side {
        width: 60%;
        margin-right: 5%;
    }
    .right-side {
        width: 50%;
        h3 {
            font-weight: normal;
            font-size: $font-size-48;
            color: #d6d6d6;
            padding-bottom: 35px;
        }
    }
    ul {
        list-style-image: url(../images/geothermie_section04_point_rouge.jpg);
        padding-left: 30px;
        li {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-26;
            color: #d6d6d6;
            line-height: 1.3;
            text-transform: initial;
            padding-bottom: 15px;
            padding-left: 20px;
        }
    }
}

/*******************************************************************************
* NOTRE CONCEPT
*******************************************************************************/

#section-text-img.concept {
    .left-side {
        h3 {
            padding-bottom: 0;
        }
    }
    h4 {
        font-weight: normal;
        font-size: $font-size-48;
        color: #df1228;
        padding-bottom: 25px;
    }
    h4.last {
        margin-top: -45px;
    }
    .right-side {
        a.burst-red {
            background: #ee3124;
            display: flex;
            align-items: center;
            padding: 0 5%;
            width: 100%;
            height: 120px;
            margin-top: 10px;
            .text {
                width: 60%;
                padding-left: 50px;
                h4 {
                    font-weight: normal;
                    font-size: $font-size-36;
                    color: $color-white;
                    padding-bottom: 0;
                }
                h3 {
                    font-weight: normal;
                    font-size: $font-size-60;
                    color: $color-white;
                    line-height: 1;
                }
            }
            .icon {
                .img-responsive.hover {
                    display: none;
                }
            }
            &:hover {
                background: $color-black;
                .text {
                    h4, h3 {
                        color: #ee3124;
                    }
                }
                .icon {
                    .img-responsive.hover {
                        display: block;
                    }
                    .img-responsive.defaut {
                        display: none;
                    }
                }
            }
        }
    }
}
#section-parallax {
    background: url(../images/concept_section02_parallax_bg.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    h4 {
        font-weight: normal;
        font-size: $font-size-48;
        color: #df1228;
    }
}

#section-img-text {
    padding: 50px 0;
    .left-side {
        width: 50%;
        margin-right: 5%;
    }
    .right-side {
        width: 50%;
        padding-top: 20px;
        h4 {
            font-weight: normal;
            font-size: $font-size-48;
            color: #df1228;
            padding-bottom: 25px;
        }
    }
}

/*******************************************************************************
* PRODUITS
*******************************************************************************/

#section-text-img.produits {
    h5 {
        font-weight: normal;
        font-size: $font-size-36;
        color: #ee3124;
        padding-bottom: 25px;
    }
    ul {
        list-style-image: url(../images/geothermie_section04_point_rouge.jpg);
        padding-left: 30px;
        li {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-26;
            color: #ee3124;
            line-height: 1.3;
            text-transform: initial;
            padding-bottom: 15px;
            padding-left: 20px;
        }
    }
    .left-side {
        position: relative;
    }
    a.burst-red {
        background: #ee3124;
        display: flex;
        align-items: center;
        padding: 0 5%;
        width: 100%;
        height: 120px;
        margin-top: 10px;
        position: absolute;
        bottom: 0;
        .text {
            width: 65%;
            padding-left: 50px;
            h4 {
                font-weight: normal;
                font-size: $font-size-36;
                color: $color-white;
                padding-bottom: 0;
            }
            h3 {
                font-weight: normal;
                font-size: $font-size-60;
                color: $color-white;
                line-height: 1;
            }
        }
    }
    .right-side {
        .text {
            text-align: right;
            padding-top: 10px;
            p {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-26;
                color: $color-white;
                line-height: 1.2;
                margin: 0;
            }
            a.pdf {
                font-weight: normal;
                font-size: $font-size-22;
                color: #ee3124;
            }
        }
    }
}

#section-text-img.produits.black {
    padding: 100px 0;
    background: #000;
    ul {
        list-style-image: url(../images/produits_section03_point_rouge.jpg);
        padding-left: 30px;
        li {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-26;
            color: #ee3124;
            line-height: 1.3;
            text-transform: initial;
            padding-bottom: 15px;
            padding-left: 20px;
        }
    }
    a.burst-red {
        position: initial;
        margin-top: 35px;
        &:hover {
            border: 2px solid #ee3124;
        }
    }
}

#section-img-img {
    padding: 75px 0 0 0;
    h3 {
        font-weight: normal;
        font-size: $font-size-48;
        color: #d6d6d6;
        padding-bottom: 15px;
    }
    .left-side {
        width: 50%;
        margin-right: 5%;
    }
    .right-side {
        width: 50%;
        a.btn-link {
            color: #ee3124;
            text-decoration: underline !important;
            margin-left: 25px;
            font-weight: 500;
            font-size: $font-size-24;
            font-family: $font-family-2;
        }
    }
}
#section-img-img.last {
    padding-bottom: 50px;
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-text-img.produits.services{
   a.burst-red {
        background: #ee3124;
        display: flex;
        align-items: center;
        padding: 0 10%;
        width: 100%;
        height: 120px;
        margin-top: 35px;
        position: initial;
        .text {
            width: 55%;
            margin-right: 40px;
            padding-left: 0;
            h3 {
                font-weight: normal;
                font-size: $font-size-36;
                color: $color-black;
                line-height: 1;
                padding-bottom: 0;
                span {
                    font-size: $font-size-60;
                }
            }
        }
        .icon {
            width: 15%;
            .img-responsive.hover {
                display: none;
            }
        }
        &:hover {
            background: $color-black;
            .text {
                h3 {
                    color: #ee3124;
                }
            }
            .icon {
                .img-responsive.hover {
                    display: block;
                }
                .img-responsive.defaut {
                    display: none;
                }
            }
        }
    }
}

/*******************************************************************************
* SOUMISSION
*******************************************************************************/

#section-soumission {
    padding: 75px 0;
    border-top: 25px solid #ee3124;
    border-bottom: 25px solid #ee3124;

}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 75px 0;
    a.item-image {
        .grid {
            .grid__text {
                margin-top: 10px;
                padding: 20px;
                h3 {
                    font-weight: normal;
                    font-size: $font-size-28;
                    color: $color-white;
                    padding-bottom: 5px;
                }
                p {
                    font-size: $font-size-22;
                    line-height: 1.3;
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 100px 0;
    border-top: 25px solid #ee3124;
    border-bottom: 25px solid #ee3124;
    .bloc {
        display: flex;
        align-items: center;
        justify-content: center;
        .left-side {
            width: 10%;
        }
        .right-side {
            width: 28%;
            h3 {
                font-weight: normal;
                font-size: $font-size-36;
                color: #d6d6d6;
                line-height: 1.5;
            }
            h4 {
                font-weight: normal;
                font-size: $font-size-36;
                color: $color-white;
                line-height: 1.2;
            }
            a {
                font-weight: normal;
                font-size: $font-size-36;
                color: $color-white;
                line-height: 1;
                text-decoration: underline !important;
                &:hover {
                    text-decoration: none !important;
                }
            }
        }
    }
}




/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1800px) {

nav.module-menu .section-rbq {
    padding-left: 16%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu {
    margin-right: 14%;
}
#section-carousel-text .left-side .main-content .arrow.next.prev-vedette {
    right: -38px;
}
.module-header--caption {
    top: 90%;
    left: 10%;
}
}

@media screen and (max-width: 1550px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu {
    margin-right: 0;
}
#section-carousel-text .left-side .main-content .arrow.prev.next-vedette, #section-carousel-text .left-side .main-content .arrow.next.prev-vedette {
    display: none;
}
#section-carousel-text .right-side h3 {
    font-size: 2.2rem;
}
.module-header--caption {
    top: 90%;
    left: 2%;
}
}

@media screen and (max-width: 1440px) {

nav.module-menu .section-rbq {
    padding-left: 20%;
}
#section-text-burst .text-burst.flex a.right-side h3 {
    font-size: 2.5rem;
}
#section-text-burst .text-burst.flex a.right-side h4 {
    font-size: 1.5rem;
}
#section-geo-closed .text-img .right-side .image h4 {
    font-size: 1.2rem;
}
#section-img-img .right-side a.btn-link {
    display: block;
}
}

@media screen and (max-width: 1400px) {

#section-text-img .left-side h3 br {
    display: none;
}
#section-text-img.produits a.burst-red .text h4 {
    font-size: 1.6rem;
}
#section-text-img.produits a.burst-red .text h3 {
    font-size: 2.6rem;
}
#section-text-img.produits a.burst-red .text {
    width: 70%;
}
#section-text-img.produits.services a.burst-red .text {
    width: 60%;
}
#section-soumission form label {
    font-size: 1.4rem;
}
}

@media screen and (max-width: $size-md-max) {

#section-text-burst .text-burst.flex a.right-side {
    width: 30%;
    padding-left: 75px;
}
#section-text-burst .text-burst.flex a.right-side .icon {
    padding-top: 10px;
    width: 55%;
}
#section-text-img .left-side a.burst-red .text {
    width: 75%;
}
#section-img-img .left-side p.pb10 {
    padding-bottom: 4.3rem;
}
#section-logo .logo {
    padding: 0 25px;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .section-rbq {
    padding-left: 25%;
}
#section-text-burst .text-burst.flex a.right-side {
    width: 35%;
    padding-left: 60px;
}
#section-burst a.burst .text h4 {
    font-size: 1.6rem;
}
#section-burst a.burst .text h3 {
    font-size: 2.8rem;
}
#section-text-img .container.flex {
    align-items: center;
}
.module-header--caption {
    top: 86%;
    left: 2%;
}
#section-text-img {
    padding: 75px 0 50px 0;
}
#section-geo-opened .bloc h4, #section-geo-closed .text-img .right-side .image h4 {
    font-size: 1.15rem;
    text-align: center;
}
#section-text-img.concept h4.last {
    margin-top: 25px;
}
#section-text-img.produits a.burst-red .text h4 {
    font-size: 1.2rem;
}
#section-text-img.produits a.burst-red .text h3 {
    font-size: 2.2rem;
}
#section-text-img .left-side a.burst-red .text {
    padding-left: 15px;
}
#section-img-img .left-side p.pb10 {
    padding-bottom: 7rem;
}
#section-coordonnees .bloc .left-side {
    width: 14%;
}
#section-coordonnees .bloc .right-side {
    width: 40%;
}
#section-soumission .form-group.col-xs-12.col-sm-8.col-sm-push-2, #section-soumission .col-xs-12.col-sm-8.col-sm-push-2.pt5 {
    left: 5%;
    width: 80%;
}
}

@media screen and (max-width: 1080px) {

nav.module-menu .menu-navbar .wrapper.fb {
    display: none;
}
nav.module-menu .section-rbq {
    padding-left: 0;

    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;

    -webkit-align-items: center;
  	align-items: center;


    padding-right: 1.5rem;
    margin-left: auto;
}
.text-color-nav-toggle, nav.module-menu .menu-toggle .word {
    font-size: 1.2rem;
}
}

@media screen and (max-width: $size-sm-max) {

#section-text-burst .text-burst.flex {
    flex-direction: column;
}
#section-text-burst .text-burst.flex .left-side {
    width: 100%;
    margin: 0 0 25px 0;
}
#section-text-burst .text-burst.flex a.right-side {
    width: 50%;
    margin: auto;
}
#section-text-burst .text-burst.flex a.right-side h4 {
    font-size: 1.8rem;
}
#section-text-burst .text-burst.flex a.right-side h3 {
    font-size: 3rem;
}
#section-logo .logo {
    width: 50%;
    padding: 15px;
}
#section-logo .logo .img-responsive {
    margin: auto;
}
#section-burst a.burst .text h3 {
    font-size: 2.2rem;
}
#section-burst a.burst .text h4 {
    font-size: 1.4rem;
}
#section-carousel-text .container.flex {
    flex-direction: column;
}
#section-carousel-text .left-side {
    width: 100%;
    margin: 0 0 25px 0;
}
#section-carousel-text .right-side {
    width: 100%;
    padding-left: 0;
}
#section-carousel-text .right-side h3 br {
    display: none;
}
#section-text-burst {
    padding: 50px 0;
}
#section-text-img .container.flex {
    flex-direction: column;
}
#section-text-img .left-side {
    width: 100%;
    margin-right: 0;
    margin-bottom: 35px;
}
#section-text-img .left-side a.burst-red {
    padding: 0 5%;
    width: 60%;
}
.module-header--caption {
    top: 86%;
    left: 3%;
}
#section-geo-closed .text-img, #section-geo-opened .bloc, #section-img-list .container.flex {
    flex-direction: column;
}
#section-geo-closed .text-img .left-side {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
    padding-top: 0;
}
#section-geo-closed .text-img .right-side, #section-geo-opened .bloc .right-side, #section-img-list .right-side {
    width: 100%;
}
#section-geo-opened .bloc .left-side, #section-img-list .left-side, #section-img-text .left-side {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
}
#section-geo-opened .bloc h4, #section-geo-closed .text-img .right-side .image h4 {
    font-size: 1.6rem;
    text-align: initial;
}
#section-img-list .right-side h3 br {
    display: none;
}
#section-img-list .left-side .image {
    padding-bottom: 10px;
}
#section-text-img.concept h4.last {
    margin-top: 35px;
}
#section-img-text .container.flex, #section-img-img .container.flex {
    flex-direction: column;
}
#section-img-text .right-side {
    width: 100%;
    padding-top: 5px;
}
#section-text-img .left-side a.burst-red {
    position: initial;
}
#section-text-img.produits a.burst-red .text h4 {
    font-size: 1.4rem;
}
#section-text-img.produits a.burst-red .text h3 {
    font-size: 2.4rem;
}
#section-img-img .left-side {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3rem;
}
#section-img-img .left-side p.pb10 {
    padding-bottom: 3rem;
}
#section-img-img .right-side {
    width: 100%;
}
#section-coordonnees .bloc .left-side {
    width: 16%;
}
#section-soumission .form-group.col-xs-12.col-sm-8.col-sm-push-2, #section-soumission .col-xs-12.col-sm-8.col-sm-push-2.pt5 {
    left: 0;
    width: 95%;
}
#section-logo-small .container.flex.items-center {
    flex-direction: column;
}
#section-logo-small .container.flex.items-center .logo-right {
    position: initial;
    padding-top: 30px;
}
#section-logo-small .container.flex.items-center .logo.mr7 {
    margin: 25px 0;
}
#section-logo-small .container.flex.items-center .logo-right p {
    font-size: 1.4rem;
}
}

@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-toggle {
    padding-right: 0;
    padding-top: 10px;
}
nav.module-menu .menu-navbar {
    height: 70px;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 160px;
}
nav.module-menu .section-rbq {
    margin: 80px 0 0 0;
}
.slideshow-wrap .caption {
    top: 65%;
    left: 55%;
    width: 100%;
}
#section-text-burst .text-burst.flex a.right-side, #section-text-img .left-side a.burst-red {
    width: 100%
}
#section-logo-small {
    padding: 50px 0 30px 0;
}
#section-geo-opened .bloc .right-side.flex {
    flex-direction: column;
}
#section-logo-small .container.flex.items-center .logo.mr7 {
    margin: 25px 0;
}
#section-burst .container.flex {
    flex-direction: column;
}
#section-burst a.burst {
    width: 100%;
    margin-bottom: 15px;
}
#section-burst a.burst .text h3 {
    font-size: 2.5rem;
}
#section-burst a.burst .text h4 {
    font-size: 1.6rem;
}
#section-carousel-text .left-side .main-content .col-xs-12.txt img {
    height: 250px;
}
#section-text-burst .text-burst.flex {
    padding-top: 25px;
}
#section-text-img {
    padding: 50px 0;
}
#section-text {
    padding: 25px 0 50px 0;
}
.module-header--caption {
    top: 94%;
    left: 5%;
}
#section-geo-closed .text-img .right-side .image {
    width: 100%;
    padding: 10px 0;
}
#section-geo-opened .bloc .right-side.flex .image.mr3 {
    padding-bottom: 25px;
}
#section-text-img.concept .right-side a.burst-red .text {
    width: 90%;
    padding-left: 15px;
}
#section-parallax h4 br {
    display: none;
}
#section-text-img.produits.services a.burst-red .text {
    width: 70%;
}
#section-text-img.produits.services a.burst-red .text h3 span {
    font-size: 2.8rem;
}
#section-coordonnees .bloc .right-side h3, #section-coordonnees .bloc .right-side h4, #section-coordonnees .bloc .right-side a {
    font-size: 1.5rem;
}
#section-coordonnees .bloc .left-side {
    width: 16%;
    margin-right: 25px;
}
#section-coordonnees .bloc .right-side {
    width: 65%;
}
#section-coordonnees, #section-soumission {
    padding: 50px 0;
}
#section-coordonnees .container {
    padding: 0 5px;
}
#section-soumission .form-group.col-xs-12.col-sm-8.col-sm-push-2, #section-soumission .col-xs-12.col-sm-8.col-sm-push-2.pt5 {
    width: 100%;
    padding: 0;
}
#section-soumission form label {
    font-size: 1.6rem;
}
#section-soumission .flex.flex-wrap.form-group.list-checkBox.pt2.pb8 {
    flex-direction: column;
}
#section-soumission label.mx6.my2, #section-soumission label.m6.my2 {
    margin: 0;
}
#section-soumission label.flex.items-baseline {
    flex-direction: initial;
}
#section-soumission label.flex.items-baseline .titre {
    width: 150px;
}
}

@media screen and (max-width: 360px) {

#section-text-img.produits.services a.burst-red {
    padding: 0 5%;
}
#section-coordonnees .bloc .right-side h3, #section-coordonnees .bloc .right-side h4, #section-coordonnees .bloc .right-side a {
    font-size: 1.4rem;
}
}
